import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Alert,
	Box,
	Button,
	ButtonDropdown,
	Container,
	ContentLayout,
	Grid,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import ApprovalDetails from '../../../components/Platform/ApprovalDetails'
import ApprovalForm from '../../../components/Platform/ApprovalForm'
import RejectionForm from '../../../components/Platform/RejectionForm'
import LoadingSpinner from '../../../components/LoadingSpinner'
import CONSTANTS from '../../../constants'
import useSinglePlatform from '../../../hooks/query/useSinglePlatform'
import RequiredPermissions from '../../../helper/RequiredPermissions'
import CommentsOverview from '../../../components/Platform/CommentsOverview'
import RenderPlatformFields from './RenderPlatformFields'
import { Platform } from '../../../types/platforms'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
import AccessDetails from '../../../components/Platform/AccessDetails'
import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsRead'
import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEdit'

const PlatformDetails: FC = () => {
	const CURRENT_USER_ID = JSON.parse(localStorage['okta-token-storage'])[
		'idToken'
	]['claims']['email']
	const { id } = useParams() as { id: string }
	const navigate = useNavigate()
	const { data: thisPlatform, isLoading } = useSinglePlatform(id)
	const thisPlatformID = thisPlatform?.platformID || ''
	const {
		data: userPermissions,
		isLoading: isLoadingPermissions,
		error: permissionsError
	} = useUserPermissions(CURRENT_USER_ID)
	const hasSpecificPermissions = (
		permissions: string[],
		requiredPermissions: string[]
	): boolean => {
		return requiredPermissions.some((requiredPerm) =>
			permissions.includes(requiredPerm)
		)
	}
	const specificPermissions = ['platforms:*:*', 'platforms:*:approve', '*:*:*']
	const hasRequiredPermissions =
		userPermissions &&
		hasSpecificPermissions(userPermissions.permissions, specificPermissions)
	const [showApprovalForm, setShowApprovalForm] = useState(false)
	const [showRejectionForm, setShowRejectionForm] = useState(false)
	const [showButton, setShowButton] = useState(true)
	function handleShowAccessDetails(): void {
		setShowButton(!showButton)
	}

	if (!thisPlatform || isLoading) return <LoadingSpinner />

	if (thisPlatform.status === 'ERROR') {
		return (
			<Alert
				statusIconAriaLabel="Error"
				type="error"
				header="An error occurred"
			>
				{thisPlatform.details.message}
			</Alert>
		)
	}
	const handleApproveClick = () => {
		setShowApprovalForm(true)
		setShowRejectionForm(false)
	}

	const handleRejectClick = () => {
		setShowRejectionForm(true)
		setShowApprovalForm(false)
	}
	const platformVersionedObject = getKeyValuePlatform(thisPlatform)

	console.log(thisPlatform)
	let isAllTagsApproved = false
	if (thisPlatform.tags !== undefined) {
		isAllTagsApproved = true
		let platformTags = thisPlatform.tags
		let platformTagsApprovalStatuses = platformTags.approvalStatus
		for (const tagStatus of platformTagsApprovalStatuses) {
			if (tagStatus.status !== 1) {
				isAllTagsApproved = false
			}
		}
	}

	console.log(isAllTagsApproved)
	return (
		<ContentLayout
		header={
			<HeaderWithStatusBadges
				headerName={thisPlatform?.platformName}
				status={thisPlatform?.status}
			/>
		}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 3, xxs: 9 } },
					{ colspan: { default: 9, xxs: 3 } }
				]}
			>
				<SpaceBetween direction="vertical" size="l">
					<RenderPlatformFields Platform={platformVersionedObject} />

					{!hasRequiredPermissions && (thisPlatform.status === 'PENDING_APPROVAL') && (
						<RequiredPermissions requiredPermissions={CONSTANTS.PLATFORM_REQUESTOR}>
							<Container 
								header={<Header variant='h3'>Tag Details</Header>}>
									{thisPlatform.tagsID && (
										<ReadGenericTags tagsId={thisPlatform.tagsID}/>
									)}
							</Container>
						</RequiredPermissions>
					)}

					{(thisPlatform.status === 'APPROVED' || thisPlatform.status === 'REJECTED') && (
						<RequiredPermissions requiredPermissions={CONSTANTS.PLATFORM_REQUESTOR}>
							<Container header={<Header variant='h3'>Tag Details</Header>}>
								{thisPlatform.tagsID && (
									<ReadGenericTags tagsId={thisPlatform.tagsID} />
								)}
							</Container>
						</RequiredPermissions>
					)}

					{thisPlatform.status === 'PENDING_APPROVAL' && hasRequiredPermissions && (
						<RequiredPermissions requiredPermissions={CONSTANTS.PLATFORM_APPROVER}>
							<Container header={<Header variant='h3'>Tag Details</Header>}>
								{thisPlatform.tagsID && (
									<EditGenericTags tagsId={thisPlatform.tagsID}/>
								)}
							</Container>
						</RequiredPermissions>
					)}

					{thisPlatform.status === 'PENDING_APPROVAL' &&
						CURRENT_USER_ID !== thisPlatform.requestorID && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.PLATFORM_APPROVER}
							>
								<Box float="left">
									<ButtonDropdown
										items={isAllTagsApproved ? [
											{text: 'To Approve', id: 'to-approve'},
											{text: 'To Reject', id: 'to-reject'}
										]: [
											{ text: 'To Reject', id: 'to-reject' }
										]}
										onItemClick={({detail}) => {
											if (detail.id === 'to-approve') {
												setShowApprovalForm(true)
												setShowRejectionForm(false)
											}

											if (detail.id === 'to-reject') {
												setShowApprovalForm(false)
												setShowRejectionForm(true)
											}
										}} variant='primary'>
											Action
										</ButtonDropdown>
								</Box>
							</RequiredPermissions>
						)}
					{showApprovalForm && (
						<ApprovalForm
							thisPlatformID={thisPlatformID}
							shouldShowApprovalForm={setShowApprovalForm}
						/>
					)}

					{showRejectionForm && (
						<RejectionForm
							thisPlatformID={thisPlatformID}
							shouldShowRejectionForm={setShowRejectionForm}
						/>
					)}
					{thisPlatform.status === 'APPROVED' && (
						<RequiredPermissions
							requiredPermissions={CONSTANTS.PLATFORM_APPROVER}
						>
							<ApprovalDetails approvalDetails={thisPlatform!} />
						</RequiredPermissions>
					)}

					{thisPlatform.status === 'APPROVED' &&
						CURRENT_USER_ID !== thisPlatform.requestorID &&
						showButton && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.APPLICATION_APPROVER}
							>
								<Button variant="primary" onClick={handleShowAccessDetails}>
									View Access Details
								</Button>
							</RequiredPermissions>
						)}

					{!showButton && (
						<AccessDetails platformName={thisPlatform?.platformName}
						env={thisPlatform?.environment} />
					)}
				</SpaceBetween>

				<SpaceBetween direction="vertical" size="l">
					<Container header={<Header variant="h3">Comment History</Header>}>
						<CommentsOverview comments={thisPlatform.comments} />
					</Container>
				</SpaceBetween>
			</Grid>
		</ContentLayout>
	)
}

const getKeyValuePlatform = (platform: Platform) => {
	console.log(platform, 'platform')
	const baseEntries = [
		{
			label: 'Requestor',
			value: platform?.requestorID,
			version: getVersionStr(platform?.version, 'requestorID')
		},
		{
			label: 'Platform Name',
			value: platform?.platformName,
			version: getVersionStr(platform?.version, 'platformName')
		},
		{
			label: 'Platform Owner',
			value: platform?.platformOwner,
			version: getVersionStr(platform?.version, 'platformOwner')
		},
		{
			label: 'Group',
			value: platform?.group,
			version: getVersionStr(platform?.version, 'group')
		},
		{
			label: 'System Alias',
			value: platform?.systemAlias,
			version: getVersionStr(platform?.version, 'systemAlias')
		},
		{
			label: 'GCP Project ID',
			value: platform?.gcpProjectID,
			version: getVersionStr(platform?.version, 'gcpProjectID')
		},
		{
			label: 'Platform Type',
			value: platform?.platformType,
			version: getVersionStr(platform?.version, 'platformType')
		},
		{
			label: 'Platform Size',
			value: platform?.platformSize,
			version: getVersionStr(platform?.version, 'platformSize')
		},
		{
			label: 'Environment',
			value: platform?.environment,
			version: getVersionStr(platform?.version, 'environment')
		},
		{
			label: 'Region',
			value: platform?.region,
			version: getVersionStr(platform?.version, 'region')
		}
	]
	
	const additionalEntries = platform?.minNode
		? [
				{
					label: 'Minimum Node',
					value: platform?.minNode,
					version: getVersionStr(platform?.version, 'minNode')
				},
				{
					label: 'Maximum Node',
					value: platform?.maxNode,
					version: getVersionStr(platform?.version, 'maxNode')
				},
				{
					label: 'Machine Type',
					value: platform?.machineType,
					version: getVersionStr(platform?.version, 'machineType')
				}
		  ]
		: []
	return [...baseEntries, ...additionalEntries]
}

const getVersionStr = (version: Array<any> | undefined, key: string) => {
	let versionItems: string[] = []
	version?.forEach((element) => {
		if (!element[key]) return
		if (key === 'region' && element.region) {
			versionItems.unshift(`v${element.versionID}: ${element.region[0].label}`)
		} else {
			versionItems.unshift(`v${element.versionID}: ${element[key]}`)
		}
	})
	return versionItems
}

export default PlatformDetails